import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";

const names = [
  { name: "Axirus", image: "url('/assets/images/Axirus.png')" },
  { name: "Clean Labs Bio", image: "url('/assets/images/Clean Labs Bio.png')" },
  { name: "Gaontara", image: "url('/assets/images/Gaontara.png')" },
  {
    name: "Pentaone Healthcare",
    image: "url('/assets/images/Pentaone Healthcare.png')",
  },
  { name: "Prithvi Art", image: "url('/assets/images/Prithvi Art.png')" },
  { name: "Solit", image: "url('/assets/images/Solit.png')" },
  { name: "Stex Pen", image: "url('/assets/images/stexpen.png')" },
  {
    name: "Travellers Nest",
    image: "url('/assets/images/Travellers Nest.png')",
  },
  {
    name: "Automation Tech",
    image: "url('/assets/images/Automation Tech.png')",
  },
  {
    name: "Fleek World",
    image: "url('/assets/images/Fleek World.png')",
  },
  {
    name: "Nakoda Group",
    image: "url('/assets/images/Nakoda Group.png')",
  },
  {
    name: "Riico",
    image: "url('/assets/images/Riico.png')",
  },
];

function Home() {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [showHeading, setShowHeading] = useState(true);

  useEffect(() => {
    AOS.init({});
  }, []);

  return (
    <>
      <div className="mt-3 fixed-top ms-lg-8 ps-lg-7 ps-4 z-0">
        <div className="container">
          <a href="https://design1.cranberry.media/#menu">
            <img
              src="/assets/images/menu.png"
              width="35"
              type="button"
              class="me-3"
            />
          </a>
          <img src="/assets/images/logo.png" width="180" />
        </div>
      </div>

      <div className="container pt-lg-4 pt-8 mt-lg-0 mt-8">
        <div className="row justify-content-end">
          <div
            className="col-lg-4 col-8 position-relative z-5"
            data-aos="fade-down"
            data-aos-offset="300"
            data-aos-duration="700"
          >
            {names.map((item, index) => (
              <div className="d-flex flex-row">
                <div className="dots d-flex align-items-center">
                  {hoveredIndex === index ? (
                    <i className="bi bi-circle-fill fs-5 text-red me-3"></i>
                  ) : (
                    ""
                  )}
                </div>
                <h2
                  key={index}
                  className={`text-uppercase fs-fantasy mb-0 cursor-pointer text-container word word${
                    index + 1
                  } ${hoveredIndex === index ? "hovered" : ""}`}
                  style={{
                    color: hoveredIndex === index ? "red" : "white",
                    opacity:
                      hoveredIndex !== null && hoveredIndex !== index ? 0.3 : 1,
                  }}
                  onMouseEnter={() => {
                    setHoveredIndex(index);
                    document.body.style.backgroundImage = item.image;
                    document.body.style.backgroundSize = "cover";
                    document.body.style.backgroundPosition = "center";
                    document.body.style.backgroundRepeat = "no-repeat";
                    setShowHeading(false);
                  }}
                  onMouseLeave={() => {
                    setHoveredIndex(null);
                    document.body.style.backgroundImage = "";
                    setShowHeading(true);
                  }}
                >
                  {item.name.split("").map((char, index) => (
                    <span
                      key={index}
                      className="char"
                      style={{ animationDelay: `${index * 0.01}s` }}
                    >
                      {char}
                    </span>
                  ))}
                </h2>
              </div>
            ))}
          </div>
        </div>
      </div>

      <h1
        className={`text-uppercase fw-bold display-1 mb-lg-0 text-center heading-text fixed-bottom z-0 ${
          showHeading ? "slide-down" : "slide-up"
        }`}
      >
        {Array.from("Cranberry").map((char, index) => (
          <span
            key={index}
            className="slide-in"
            style={{
              animationDelay: `${60 * index}ms`,
            }}
          >
            {char}
          </span>
        ))}
      </h1>
    </>
  );
}

export default Home;
